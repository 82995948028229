<template>
  <label
    class="app-checkbox"
    :class="{'app-checkbox--error': error}"
  >
    <input
      type="checkbox"
      class="app-checkbox__input"
      :checked="value"
      @input="$emit('input', $event.target.checked)"
      @change="$emit('change', $event.target.checked)"
    />
    <span class="app-checkbox__label">
      <slot/>
    </span>
    <span v-if="error" class="app-checkbox__error">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M9.06997 2.80002L14.75 12.92C15.35 13.98 14.85 14.83 13.65 14.83H2.34997C1.14997 14.83 0.649968 13.98 1.24997 12.93L6.92997 2.78002C7.52997 1.73002 8.47997 1.72002 9.06997 2.78002V2.80002ZM8.99997 11H6.99997V13H8.99997V11ZM8.99997 6.00002H6.99997V10H8.99997V6.00002Z" fill="#F94839"></path></svg>
      {{ error }}
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.app-checkbox {
  $root: &;

  display: flex;
  flex-flow: column;

  position: relative;

  padding-bottom: 22px;

  &__input {
    display: none;
  }

  &__label {
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;

    display: flex;
    align-items: center;

    cursor: pointer;

    &::before {
      content: '';
      display: block;
      height: 22px;
      width: 22px;
      flex: 0 0 22px;
      margin-right: 10px;

      border: 1px solid #d1d1d1;
      border-radius: 4px;
      outline: none;

      #{$root}--error & {
        border-color: #f94839;
      }
    }

    #{$root}__input:checked + & {
      &::before,
      &::after {
        border: 1px solid #317ae2;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 10px;
        height: 11px;
        width: 22px;
        border-width: 4px;
        border-top-color: transparent;
        border-right-color: transparent;
        transform: translate(-2px,-50%) rotate(-45deg);
      }
    }
  }

  &__error {
    position: absolute;
    bottom: 2px;

    display: flex;
    align-items: center;

    font-size: 14px;

    color: #f94839;

    svg {
      margin-right: 6px;
    }
  }
}
</style>