<template>
  <div class="data">
    <div class="data__form">
      <app-input
        v-model.trim="usersData.name"
        :label="$t('data.fields.name')"
        :error="errors.name"
        @input="clearError('name')"
      />
      <app-input
        v-model.trim="usersData.email"
        :label="$t('data.fields.email')"
        @input="clearError('email')"
        :error="errors.email"
      />
      <app-input
        v-model.trim="usersData.subject"
        :label="$t('data.fields.subject')"
        :error="errors.subject"
        @input="clearError('subject')"
      />
      <app-checkbox
        v-model="agb"
        :label="$t('data.fields.agb')"
        :error="errors.agb"
        @input="clearError('agb')"
      ><span v-html="$t('data.fields.agb')"/></app-checkbox>
    </div>

    <div class="data__buttons">
      <router-link :to="{ name: 'home' }" class="btn btn--secondary data__btn-back">{{ $t('data.back') }}</router-link>
      <a
        @click="next"
        :class="{ 'disabled': !agb }"
        class="btn data__btn-next"
      >{{ $t('data.next') }}</a>
    </div>
  </div>
</template>

<script>
import AppInput from '@/components/UI/AppInput.vue';
import AppCheckbox from '@/components/UI/AppCheckbox.vue';

export default {
  components: {
    AppInput,
    AppCheckbox,
  },
  data() {
    return {
      errors: {
        name: '',
        subject: '',
        email: '',
        agb: '',
      },
      agb: false,
    };
  },
  computed: {
    usersData() {
      return this.$store.state.usersData
    },
  },
  methods: {
    validate() {
      const { name, subject, email } = this.usersData;
      const testNameReg = /^[a-zßüÜöÖäÄ][a-zßüÜöÖäÄ -]*$/i;
      // eslint-disable-next-line no-useless-escape
      const testEmailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

      if (!name) {
        this.errors.name = this.$t('data.errors.isRequired');
      } else if (name.length < 2) {
        this.errors.name = this.$t('data.errors.minLength', { minlen: 2});
      } else if (!testNameReg.test(name)) {
        this.errors.name = this.$t('data.errors.onlyLatin');
      } else {
        this.errors.name = '';
      }

      if (!subject) {
        this.errors.subject = this.$t('data.errors.isRequired');
      } else if (subject.length < 2) {
        this.errors.subject = this.$t('data.errors.minLength', { minlen: 2});
      } else {
        this.errors.subject = '';
      }

      if (!email) {
        this.errors.email = this.$t('data.errors.isRequired');
      } else if (!testEmailReg.test(email)) {
        this.errors.email = this.$t('data.errors.unvalidEmail');
      } else {
        this.errors.email = '';
      }

      return Object.values(this.errors).filter((err) => err).length === 0;
    },
    next() {
      if (this.validate()) {
        this.$router.push({ name: 'player' });
      }
    },
    clearError(field) {
      this.errors[field] = '';
    }
  },
}
</script>

<style lang="scss">
.data {
  &__buttons {
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    &-back {
      flex: 0 0 30%;
    }
    &-next {
      flex: 0 0 60%;
    }
  }
}
</style>