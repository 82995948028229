<template>
  <label
    class="app-input"
    :class="{'app-input--error': error}"
  >
    <span class="app-input__label">{{ label }}</span>
    <input
      class="app-input__input"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      :maxlength="maxlength"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event.target.value)"
    />
    <span v-if="error" class="app-input__error">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M9.06997 2.80002L14.75 12.92C15.35 13.98 14.85 14.83 13.65 14.83H2.34997C1.14997 14.83 0.649968 13.98 1.24997 12.93L6.92997 2.78002C7.52997 1.73002 8.47997 1.72002 9.06997 2.78002V2.80002ZM8.99997 11H6.99997V13H8.99997V11ZM8.99997 6.00002H6.99997V10H8.99997V6.00002Z" fill="#F94839"></path></svg>
      {{ error }}
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 100,
    },
    error: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.app-input {
  $root: &;

  display: flex;
  flex-flow: column;

  position: relative;

  padding-bottom: 22px;

  &__label {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;
  }

  &__input {
    box-sizing: border-box;
    width: 100%;
    max-width: 100;
    height: 44px;
    padding: 10px 12px;

    border: 1px solid #d1d1d1;
    border-radius: 4px;
    outline: none;

    &:focus {
      border-color:  #317ae2;
    }

    #{$root}--error & {
      border-color: #f94839;
    }
  }

  &__error {
    position: absolute;
    bottom: 2px;

    display: flex;
    align-items: center;

    font-size: 14px;

    color: #f94839;

    svg {
      margin-right: 6px;
    }
  }
}
</style>